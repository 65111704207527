<template>
  <v-container style="max-width: 1160px">
    <!-- Pc -->
    <v-row class="d-none d-md-flex px-5">
      <v-col cols="7">
        <AboutInfo/>
      </v-col>

      <v-col cols="5">
        <div class="d-flex align-start flex-column fill-height ">
          <CallbackBlock class="mb-auto" style="width: 100%"/>

          <!--<v-img src="img/about.png" class="Image"></v-img>-->
        </div>

      </v-col>
    </v-row>
    <!-- Mobile -->
    <v-row class="d-flex d-md-none  px-5" >
      <v-col class=" " cols="12">
        <CallbackBlock class="mx-auto" style="max-width: 550px"/>
      </v-col>

      <v-col cols="12">
        <AboutInfo class="mx-auto"/>
      </v-col>

      <!--<v-col cols="12">
        <v-img src="img/about.png" max-width="450" class="mx-auto mt-3" max-height="250" style="width: 100%"></v-img>
      </v-col>-->
    </v-row>

    <img src="img/FlagmanText.svg" class="FlagmanText"/>
    <img :src="imageUrl" class="FlagmanText2"/>
  </v-container>
</template>

<script>

  import AboutInfo from "../components/about_page/AboutInfo";
  import CallbackBlock from "../components/about_page/CallbackBlock";

  export default {
    name: "AboutPage",
    components: {CallbackBlock, AboutInfo},
    data: () => ({}),
    computed: {
      imageUrl: function () {
        if (this.$i18n.locale == "kg")
          return 'img/FlagmanText2_kg.svg';
        return 'img/FlagmanText2.svg';
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .Column {
    width: 474px !important;
  }




  .Image {
    margin-top: 217px;
  }

  .FlagmanText {
    position: absolute;
    left: 0;
    right: 0;
    top: 57.17%;
    bottom: 18.23%;
    opacity: 0.6;
  }

  .FlagmanText2 {
    position: absolute;
    left: 0%;
    right: 37.85%;
    top: 76.81%;
    bottom: 13.2%;
    opacity: 0.4;
  }
</style>