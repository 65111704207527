import Vue from 'vue'
import VueRouter from 'vue-router';
import StartPage from '../pages/StartPage'
import DownloadPage from '../pages/DownloadPage'
import AboutPage from "../pages/AboutPage";
import WhatsNewPage from "../pages/WhatsNewPage";

Vue.use(VueRouter);

const routes = [
  { path: '/', component: StartPage },
  { path: '/download', component: DownloadPage },
  { path: '/about', component: AboutPage },
  { path: '/whats-new', component: WhatsNewPage },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if (to.path === '/download2')
  {
    console.log(to);
    if(navigator.userAgent.includes("Android")){
      location.replace('https://play.google.com/store/apps/details?id=kg.flagman.app');
    }
    else if(navigator.userAgent.includes("iPhone")){
      location.replace('https://apps.apple.com/us/app/flagman-kg/id1559561472');
    }
    else {
      next({ path: '/download', replace: true })
    }

  }
  else next()
})

export default router;