<template>

  <v-container style="max-width: 1160px">
    <table style="width: 100%">
      <tr>
        <td width="60">
          <img src="img/MainLogo.svg" class=""/>
        </td>
        <td >
          <!-- Pc -->
          <div class="d-none d-md-flex ">

              <router-link class="LinkStyle" exact-active-class="ActiveLink" to="/">{{$t('nav.registration')}}
              </router-link>

              <router-link class="LinkStyle" exact-active-class="ActiveLink" to="/download">
                {{$t('nav.downloadApp')}}
              </router-link>

              <router-link class="LinkStyle" exact-active-class="ActiveLink" to="/about">{{$t('nav.about')}}
              </router-link>

              <router-link class="LinkStyle" exact-active-class="ActiveLink" to="/whats-new">
                {{$t('nav.whatsNew')}}
              </router-link>

          </div>

          <!-- Mobile -->
          <div class="d-flex d-md-none ">
            <v-menu top offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="BtnTextStyle">
                  {{$t(currentPage)}}
                  <v-icon right dark>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        link
                        @click="navigate(item)">
                  <v-list-item-title>{{ $t(item.titleKey) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </td>
        <td width="80">
          <LocaleSelector class=""/>
        </td>
      </tr>
    </table>


  </v-container>
</template>

<script>
  import LocaleSelector from "./LocaleSelector";

  export default {
    name: "Header",
    components: {LocaleSelector},
    computed: {
      items: function () {
        return [
          {title: this.$t('nav.registration'), route: '/', titleKey:'nav.registration'},
          {title: this.$t('nav.downloadApp'), route: '/download', titleKey:'nav.downloadApp'},
          {title: this.$t('nav.about'), route: '/about', titleKey:'nav.about'},
          {title: this.$t('nav.whatsNew'), route: '/whats-new', titleKey:'nav.whatsNew'},
        ]
      },
    },
    data() {
      return {
        currentPage: ""
      }
    },
    methods: {
      navigate(item) {
        if (item) {
          this.$router.push(item.route);
          this.currentPage = item.titleKey;
        }
      }
    },
    mounted() {
      const route = this.$router.currentRoute.path;
      const item = this.items.find(i => i.route === route);
      this.currentPage = item ? item.titleKey : "";
    }
  }
</script>

<style scoped>
  .Header {
    background: #FFFFFF;
    height: 91px;
    vertical-align: center;
  }

  .ActiveLink {
    color: #3780FE !important;
  }

  .LinkStyle {
    text-decoration: none;
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #171F2A;
    margin-right: 75px;
  }


  .BtnTextStyle {
    text-transform: none !important;
    padding: 0 !important;
  }

</style>