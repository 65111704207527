<template>
  <v-container style="max-width: 1160px">
    <!-- Pc -->
    <v-row class="d-none d-md-flex px-5">
      <v-col cols="8">
        <v-img :src="imageUrl" max-width="800" class=""></v-img>
      </v-col>

      <v-col cols="4">
        <div class="d-flex align-start flex-column fill-height px-5">
          <MainRegBlock class="mb-auto" style="width: 350px"/>

        <!--  <v-img src="img/main_block2.jpg" max-width="450" contain max-height="250" style="width: 100%"></v-img> -->
        </div>

      </v-col>
    </v-row>
    <!-- Mobile -->
    <v-row class="d-flex d-md-none  px-5" >
      <v-col class=" " cols="12">
        <MainRegBlock class="mx-auto" style="max-width: 450px"/>
      </v-col>

      <v-col cols="12">
        <v-img :src="imageUrl" max-width="450" class="mx-auto mt-3"></v-img>
      </v-col>

      <!--<v-col cols="12">
        <v-img src="img/main_block2.jpg" max-width="450" class="mx-auto mt-3" max-height="250" style="width: 100%"></v-img>
      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
  import MainRegBlock from "../components/start_page/MainRegBlock";

  export default {
    name: "StartPage",
    components: {MainRegBlock},
    data: () => ({}),
    computed: {
      imageUrl: function () {
        if (this.$i18n.locale == "kg")
          return 'img/main_block_kg.png';
        return 'img/main_block.png';
      },
    },

    methods: {},
  };
</script>

<style scoped>


</style>