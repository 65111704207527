<template>
  <div class="mb-auto">
    <v-row>
      <span class="RegBlock_text1">{{$t('startPage.registerBlock.title')}}</span>
    </v-row>
    <v-row>
      <v-text-field class="RegBlock_textBox centered-input" v-model="phone"
                    :placeholder="$t('startPage.registerBlock.txtPhone')"
                    outlined
                    rounded
      ></v-text-field>
    </v-row>

    <v-row>
      <v-text-field class="RegBlock_textBox centered-input" v-model="name"
                    :placeholder="$t('startPage.registerBlock.txtName')"
                    outlined
                    rounded
      ></v-text-field>
    </v-row>
    <v-row>
      <v-text-field class="RegBlock_textBox centered-input" v-model="email"
                    :placeholder="$t('startPage.registerBlock.txtEmail')"
                    outlined
                    rounded
      ></v-text-field>
    </v-row>

    <v-row>
      <v-btn class="RegBlock_button"
             elevation="2"
             rounded v-on:click="send"
      >{{$t('startPage.registerBlock.btnSend')}}
      </v-btn>
    </v-row>

    <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5">
          Ваше сообщение доставлено.
        </v-card-title>
        <v-card-text style="font-size: 18px">Данный функционал в разработке. Мы обязательно свяжемся с вами после
          окончательной настройки бизнес аккаунтов.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
          >
            Понятно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import HttpClient from "../../api/HttpClient";

  export default {
    name: "MainRegBlock",
    data: () => ({
      dialog: false,
      phone: "",
      name: "",
      email: ""
    }),
    methods: {
      async send() {
        const data = {
          phone: this.phone,
          name: this.name,
          email: this.email
        };

        const result = await HttpClient.PostJson('/v1/extra/requestBusinessAccount', data);

        if (result.data.success) {
          this.dialog = true;
          this.phone = "";
          this.name = "";
          this.email = "";
        } else {
          let message = result.error.data[0].message;
          alert(message);
        }

      }
    }
  }
</script>

<style scoped>

  .RegBlock_text1 {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #171F2A;
  }

  .RegBlock_textBox {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.8px;

    margin-top: 26px;
    margin-bottom: -26px;

  }

  /deep/ .centered-input input {
    text-align: center
  }

  .RegBlock_button {
    background: linear-gradient(90deg, rgba(0, 148, 255, 0.9) 0%, rgba(63, 87, 255, 0.9) 100%);
    width: 100% !important;
    height: 56px !important;
    color: #FFFFFF;
    margin-top: 26px;
  }
</style>