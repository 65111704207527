import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './plugins/store'
import router from './plugins/router'
import {i18n} from "./plugins/i18n";


Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')