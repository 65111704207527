<template>
  <div>
    <v-menu top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="BtnTextStyle">
          {{currentLocale}}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
                v-for="(item, index) in items"
                :key="index"
                link
                @click="setLocale(item.locale)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>


</template>

<script>
  export default {
    name: "LocaleSelector",
    data() {
      return {
        items: [
          {title: 'Рус', locale: 'ru'},
          {title: 'Кыр', locale: 'kg'},
          {title: 'Eng', locale: 'en'},
        ],
      }
    },
    computed: {
      currentLocale: function () {
        return this.items.find(value => value.locale === this.$i18n.locale).title;
      }
    },
    methods: {
      setLocale(locale) {
        this.$i18n.locale = locale;
      }
    }
  }
</script>

<style scoped>
  .BtnTextStyle {
    text-transform: none !important;
    padding: 0 !important;
  }
</style>