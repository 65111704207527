<template>
  <v-container style="max-width: 1160px">
    <v-row class="TopOffset">
      <v-col class="Phone" v-on:click="downloadIos">
        <div>
          <img src="img/apple_icon.svg" class="Logo1"/>

          <span class="Text1_1">iOS</span>
        </div>
        <div>
          <span class="Text1_2">{{$t('downloadPage.downloadIos')}}</span>
        </div>

        <v-img src="img/apple.png" contain max-height="540" style="width: 100%"></v-img>

      </v-col>

      <v-col class="Phone" v-on:click="downloadAndroid">
        <div>
          <img src="img/android_icon.svg" class="Logo2"/>
          <span class="Text2_1">ANDROID</span>
        </div>
        <div>
          <span class="Text2_2">{{$t('downloadPage.downloadAndroid')}}</span>
        </div>

        <v-img src="img/google.png" contain max-height="540" style="width: 100%"></v-img>

      </v-col>

      <img src="img/FlagmanText.svg" class="FlagmanText"/>
      <img :src="imageUrl" class="FlagmanText2"/>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "DownloadPage",
    data: () => ({}),

    methods: {
      downloadIos(){
        window.open("https://apps.apple.com/us/app/flagman-kg/id1559561472", '_blank').focus();
      },
      downloadAndroid(){
        window.open("https://play.google.com/store/apps/details?id=kg.flagman.app", '_blank').focus();
      }
    },
    computed: {
      imageUrl: function () {
        if (this.$i18n.locale == "kg")
          return 'img/FlagmanText2_kg.svg';
        return 'img/FlagmanText2.svg';
      },
    },
  };
</script>

<style scoped>
  .TopOffset {
    top: 105px;
    text-align: center;
  }

  .Logo1 {
    margin-right: 14px;
  }

  .Text1_1 {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 22px;
  }

  .Text1_2 {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .Logo2 {
    margin-right: 14px;
  }

  .Text2_1 {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 22px;
  }

  .Text2_2 {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
  }

  .Image2 {
    width: 537px;
    height: 537px;
  }

  .Phone {
    color: #4A4A4A;
    z-index: 2;
  }

  .Phone:hover {
    background: linear-gradient(90deg, rgba(0, 148, 255, 0.9) 0%, rgba(63, 87, 255, 0.9) 100%);
    color: white;
    cursor: pointer;
  }

  .FlagmanText {
    position: absolute;
    left: 0;
    right: 0;
    top: 57.17%;
    bottom: 18.23%;
    opacity: 0.6;
  }

  .FlagmanText2 {
    position: absolute;
    left: 0%;
    right: 37.85%;
    top: 76.81%;
    bottom: 13.2%;
    opacity: 0.4;
  }
</style>