<template>
  <v-container style="max-width: 1160px">
    <div class="">
      <v-divider></v-divider>
      <v-footer class="Background px-2">

        <v-card class="flex" flat tile>
          <v-card-title class="Background" style="padding: 0 !important;">
            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.3496 1.41206C30.3496 1.41206 15.4184 -0.473478 9.24261 3.82225C7.22045 5.16124 4.83211 13.7254 4.83211 13.7254C5.02603 13.4889 5.2341 13.2644 5.45516 13.0531C6.7559 11.8071 10.5598 9.35314 19.8726 9.36407C22.6435 9.36407 26.5895 9.36409 28.7264 6.38004C29.8249 4.97545 30.3496 1.41206 30.3496 1.41206Z"
                    stroke="#4A4A4A" stroke-miterlimit="10"/>
              <path d="M19.4901 9.36414C19.4901 9.36414 8.59774 9.05809 4.83215 13.7364C3.54781 17.6003 2.14323 21.9944 2.14323 21.9944C2.14323 21.9944 3.87573 19.1962 12.9372 18.0048C16.5989 17.5348 19.5229 12.3427 19.4901 9.36414Z"
                    stroke="#4A4A4A" stroke-miterlimit="10"/>
              <path d="M2.14312 22.0108L1.1976 25.0441C1.1976 25.0441 -0.157765 29.3835 4.23087 29.2523C8.6195 29.1212 9.69617 24.8473 9.69617 24.8473L12.0954 18.1359C12.0954 18.1359 7.63028 18.7535 4.7774 20.1308C4.31312 20.3496 3.86232 20.596 3.42745 20.8686C2.91933 21.1485 2.48047 21.5388 2.14312 22.0108V22.0108Z"
                    stroke="#4A4A4A" stroke-miterlimit="10"/>
            </svg>

            <span class="FooretText ml-2">&#169; {{ new Date().getFullYear() }} Flagman</span>

            <v-spacer></v-spacer>

            <span class="FooretText"><a href= "mailto:support@fm.kg">support@fm.kg</a></span>
           <!-- <v-btn class="ml-1 IconButton" icon>
              <v-icon size="20px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="mx-0 IconButton" icon>
              <v-icon size="20px">mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="mx-0 IconButton" icon>
              <v-icon size="20px">mdi-twitter</v-icon>
            </v-btn> -->
          </v-card-title>
        </v-card>

      </v-footer>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped>
  .Background {
    /* background:  #E5E5E5  !important;*/
    background: #FFFFFF !important;
  }
  .IconButton{
    width: 22px !important;
  }

  .FooretText {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
    color: #4A4A4A;
  }

</style>