<template>
  <v-container style="max-width: 1160px">
    <p class="Title">{{$t('whatsNewPage.title')}}</p>
    <div class="MainBlock">
      <table style="">
        <tr>
          <td style="vertical-align: top" class="pt-3">
            <FlagmanLogo class="d-none d-md-flex FlagmanLogo"/>
            <FlagmanLogo class="d-flex d-md-none FlagmanLogo2"/>
          </td>
          <td>
            <div class="Message">
              {{$t('whatsNewPage.message1_1')}}<br>
              <router-link to="/download">{{$t('whatsNewPage.message1_2')}}</router-link>
            </div>
          </td>
        </tr>
      </table>
      <div class="Message2">
        {{$t('whatsNewPage.message2_1')}} <br>
        {{$t('whatsNewPage.message2_2')}}<br>
        {{$t('whatsNewPage.message2_3')}}
      </div>
      
      <img src="img/ReleaseLine.png" class="ReleaseLine">

      <div class="Message3">
        {{$t('whatsNewPage.message3_1')}} <br>
        {{$t('whatsNewPage.message3_2')}}<br>
        {{$t('whatsNewPage.message3_3')}}
      </div>
    </div>

    <img src="img/FlagmanText.svg" class="FlagmanText"/>
    <img :src="imageUrl" class="FlagmanText2"/>


  </v-container>
</template>

<script>


  import FlagmanLogo from "../components/whats_new/FlagmanLogo";
  export default {
    name: "WhatsNewPage",
    components: {FlagmanLogo },
    data: () => ({}),
    computed: {
      imageUrl: function () {
        if (this.$i18n.locale == "kg")
          return 'img/FlagmanText2_kg.svg';
        return 'img/FlagmanText2.svg';
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .MainBlock {
    position: relative;
    z-index: 1;
  }

  .ReleaseLine{
  margin-top: 40px;
  }

  .Title {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 22px;
    color: #3780FE;

    margin-bottom: 44px;
  }

  .FlagmanLogo {
    vertical-align: top;
    margin-right: 20px;
  }

  .Message {
    display: inline-block;
    padding: 20px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16), 0px -1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 36px 36px 36px 36px;

    font-family: SF Compact Display;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    max-width: 800px;
    background: #FFFFFF;
  }

  .Message2 {
    display: inline-block;
    padding: 20px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16), 0px -1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 36px 36px 36px 0px;

    font-family: SF Compact Display;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    margin-top: 80px;
    max-width: 800px;
    background: #3780FE;
    color: #FFFFFF;
  }

  .Message3 {
    display: inline-block;
    padding: 20px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16), 0px -1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0px 36px 36px 36px;

    font-family: SF Compact Display;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    margin-top: 40px;
    margin-left: 170px;
    max-width: 800px;
    background: #FFFFFF;
  }

  .FlagmanText {
    position: absolute;
    left: 0;
    right: 0;
    top: 57.17%;
    bottom: 18.23%;
    opacity: 0.6;
  }

  .FlagmanText2 {
    position: absolute;
    left: 0%;
    right: 37.85%;
    top: 76.81%;
    bottom: 13.2%;
    opacity: 0.4;
  }
</style>