<template>
  <div class="">
    <p class="MainTitle">{{$t('aboutPage.info.aboutTitle')}}</p>
    <p class="Text">{{$t('aboutPage.info.aboutText')}}</p>
    <div class="Text">
      {{$t('aboutPage.info.aboutText2')}}
      <ul>
        <li>{{$t('aboutPage.info.aboutText2_1')}}</li>
        <li>{{$t('aboutPage.info.aboutText2_2')}}</li>
        <li>{{$t('aboutPage.info.aboutText2_3')}}</li>
        <li>{{$t('aboutPage.info.aboutText2_4')}}</li>
      </ul>
    </div>

    <p class="Title">{{$t('aboutPage.info.forSellerTitle')}}</p>
    <p class="Text">{{$t('aboutPage.info.forSellerText')}}</p>
    <div class="Text">
      {{$t('aboutPage.info.forSellerText2')}}
      <ul>
        <li>{{$t('aboutPage.info.forSellerText2_1')}}</li>
        <li>{{$t('aboutPage.info.forSellerText2_2')}}</li>
        <li>{{$t('aboutPage.info.forSellerText2_3')}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AboutInfo"
  }
</script>

<style scoped>
  .MainTitle {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3780FE;
  }

  .Text {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #171F2A;
  }

  .Title {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3780FE;
    margin-top: 36px;
  }

</style>