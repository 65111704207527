<template>
  <div class="">
    <p class="FormCallback">{{$t('aboutPage.feedbackForm.title')}}</p>
    <p class="my-0">
      <v-text-field class="RegBlock_textBox centered-input "
                    :placeholder="$t('aboutPage.feedbackForm.txtMessage')"
                    solo
                    rounded
      ></v-text-field>
    </p>
    <p >
      <v-btn class="SendButton mx-auto"
             elevation="2"
             rounded
      >{{$t('aboutPage.feedbackForm.btnSend')}}
      </v-btn>

     <!-- <v-btn class="HaveAccount d-inline-block mx-auto" text> {{$t('aboutPage.feedbackForm.btnRegisterAccount')}}</v-btn>-->
    </p>
  </div>
</template>

<script>
  export default {
    name: "CallbackBlock"
  }
</script>

<style scoped>
  .SendButton {
    background: linear-gradient(90deg, rgba(0, 148, 255, 0.9) 0%, rgba(63, 87, 255, 0.9) 100%);
    width: 100% !important;
    height: 56px !important;
    color: #FFFFFF;
  }
  /deep/ .centered-input input {
    text-align: center
  }

  .HaveAccount {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    margin-top: 18px;
    margin-left: 20px;

    color: #3780FE;
  }

  .FormCallback {
    font-family: SF Compact Display;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #171F2A;
  }
</style>