import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from '../locales/ru.json'
import kg from '../locales/kg.json'
import en from '../locales/en.json'

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: {
    ru,
    kg,
    en
  }
});