<template>
  <v-app class="AppStyle">
    <Header/>

    <v-main>
      <router-view></router-view>
    </v-main>

    <Footer/>
  </v-app>
</template>

<script>
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
export default {
  name: "App",
  components: {Header, Footer},
  computed: {

  },
  methods: {

  },
  data: () => ({
    //https://iconify.design/icon-sets/mdi/
    drawer: true
  }),
};
</script>

<style scoped>
  .AppStyle  {
    /*background: #E5E5E5 !important;*/
    font-family: SF Compact Display;
    font-weight: 200;
  }
</style>
